var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalWrapper',{staticClass:"promotions-popup",style:({ '--modal-width': _vm.modalWidth + 'px' }),scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"promotions-popup__header"},[_c('DayChanger'),_c('div',{staticClass:"promotions-popup__scan-btn"},[(_vm.isScanFeatureEnabled)?_c('ScanBtnSmall',{attrs:{"isScanning":_vm.isScanning,"disabled":_vm.isScanDisabled},on:{"click":_vm.triggerScan}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$tc('lastUpdated'))+": "+_vm._s(_vm.lastScanDate)+" ")])],1)],1)]},proxy:true},{key:"main",fn:function(){return [_c('section',{staticClass:"promotions-popup__main-container",class:{ 'skeleton-container': _vm.isLoading }},[_c('div',{staticClass:"promotions-popup__metrics",class:{ 'skeleton-container': _vm.isLoading }},[_c('div',{class:{
                        'promotions-popup__metric': true,
                        ['promotions-popup__metric--' + _vm.programIndicator]: true,
                    },style:({ '--title-width': '80px' }),attrs:{"data-value":_vm.activePrograms,"data-title":_vm.$tc('promotions.popup.hotelPrograms')}}),_c('div',{staticClass:"promotions-popup__metric",attrs:{"data-value":_vm.averageCompsetPrograms,"data-title":_vm.$tc('promotions.popup.avgCompsetPrograms')}}),_c('div',{staticClass:"promotions-popup__metric",attrs:{"data-value":_vm.deals,"data-title":_vm.$tc('promotions.popup.hotelMessagingAndDeals')}}),_c('div',{staticClass:"promotions-popup__metric",attrs:{"data-value":_vm.averageDeals || 0,"data-title":_vm.$tc('promotions.popup.avgCompsetMessagingAndDeals')}})]),_c('div',{class:{
                'promotions-popup__content': true,
                'skeleton-container': _vm.isLoading,
            }},[_c('table',{staticClass:"promotions-popup__table"},[_c('tr',[_c('th'),_vm._l((_vm.programs),function(program){return _c('th',{key:program,domProps:{"textContent":_vm._s(program)}})})],2),_vm._l((_vm.rows),function(row){return _c('tr',{key:row.hotelName,class:{
                            'promotions-popup__row': true,
                            'promotions-popup__row--main': row.isMainHotel,
                        }},[_c('td',{attrs:{"title":row.hotelName}},[(row.link)?_c('a',{staticClass:"promotions-popup__link",attrs:{"href":row.link,"target":"_blank"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("ClipText")(row.hotelName)))]),_c('i',{staticClass:"icon-link"})]):_c('span',[_vm._v(" "+_vm._s(_vm._f("ClipText")(row.hotelName))+" ")])]),_vm._l((row.programsData),function(status,index){return _c('td',{key:row.hotelName + index},[_c('i',{class:`icon-${ status ? 'v' : 'x' }-mark`})])})],2)})],2)]),_c('div',{staticClass:"promotions-popup__footer"},[_c('router-link',{attrs:{"to":_vm.promotionsHistoryPopup},domProps:{"textContent":_vm._s(_vm.$tc('promotions.popup.viewPromotionsHistory'))}})],1)]),_c('section',{staticClass:"events-section"},[_c('PopupEventsContainer',{attrs:{"day":_vm.day}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }